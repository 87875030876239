<template>
  <div>
    <v-overlay :value="loading">
      <v-card class="pa-6 ma-4" style="background-color: white">
        <v-row align="center" justify="center" dense>
          <v-col cols="12" class="text-center">
            <v-progress-circular
              indeterminate
              size="64"
              color="black"
            ></v-progress-circular>
          </v-col>

          <v-col cols="12" class="text-center">
            <span style="color: black"
              >กรุณารอสักครู่... ขณะนี้กำลังดาวน์โหลดระบบชำระเงิน</span
            >
            <br />
          </v-col>
          <v-col cols="12" class="text-center">
            <span style="color: red">x อย่ากดสั่งซื้อซ้ำ</span><br />
            <span style="color: red">x อย่าปิดหน้าจอ </span><br />
            <span style="color: red">x อย่ากดปุ่มรีเฟรช</span><br />
            <span style="color: red"
              >หมายเหตุ หากไม่สามารถสแกนจ่ายได้ใน 1 นาที
              กรุณาติดต่อเจ้าหน้าที่แอดมิน
              <a target="_blank" href="https://lin.ee/Ayc1oQR">คลิ๊กที่นี่</a>
            </span>
          </v-col>
        </v-row>
      </v-card>
    </v-overlay>
    <v-row align="center" justify="center" class="">
      <h1 class="text-center" style="color: #d5ab61; font-size: 30px">
        ชำระเงิน
      </h1>
    </v-row>
    <v-row align="center" justify="center" class="mb-6">
      <v-card class="text-center sec-cart mb-16">
        <div class="lotto-scroll">
          <div class="lotto-group">
            <!-- <div class="info1">เลขชุด<b>2</b>ใบ<b>12</b>ล้าน</div> -->
            <div class="pic">
              <img
                v-for="(cart, index) in carts"
                :key="index"
                :src="cart.lottery.urlImage"
                alt="235300"
                class="sizeimg pr-2 ml-2"
              />
            </div>
          </div>
        </div>
        <v-row class="text-center mt-4" align="center" justify="center">
          <h2 style="color: white">ลอตเตอรี่ที่เลือก</h2>
        </v-row>
        <v-form ref="form1" :lazy-validation="lazy">
          <v-row align="center" justify="center">
            <v-col cols="12">
              <v-row no-gutters>
                <v-col cols="3">หมายเลข</v-col>
                <v-col cols="3">จำนวน</v-col>
                <v-col cols="3">ราคา</v-col>
                <v-col cols="3">ค่าบริการ</v-col>
                <!-- <v-col cols="2">ตัวเลือก</v-col> -->
              </v-row>
            </v-col>
            <v-col cols="12"
              ><v-row v-for="(cart, index) in carts" :key="index">
                <v-col cols="3"> {{ cart.lottery.lotteryNo }} </v-col>
                <v-col cols="3">{{ cart.amount }} ใบ</v-col>
                <v-col cols="3"
                  >{{ cart.lottery.price * cart.amount }} บาท</v-col
                >
                <v-col cols="3">{{ feePrice }} บาท</v-col>
              </v-row>
              <v-divider style="margin-top: 20px"></v-divider>
              <v-row class="mt-2">
                <v-col cols="4">รวมราคา</v-col>
                <v-col cols="4">{{ totalAmount }} ใบ</v-col>
                <v-col cols="4">{{ totalPrice }} บาท</v-col>
              </v-row>
              <!-- <h1>รางวันสูงสุด 12 ล้านบาท</h1> -->
            </v-col>
          </v-row>
          <v-row class="mt-2" justify="center"
            ><v-col cols="9">
              <v-btn
                style="
                  background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
                "
                class="px-10"
                @click="home()"
                >เลือกลอตเตอรี่เพิ่ม</v-btn
              ></v-col
            ></v-row
          >
          <!-- <v-row>
            <v-col cols="12">
              <v-select
                v-model="delivery"
                :items="items"
                :item-text="items.value"
                :item-value="items.text"
                placeholder="เลือกการจัดส่ง"
                solo
                :rules="Rules.empty"
              ></v-select>
            </v-col>
          </v-row> -->
          <!-- <v-divider style="margin-top: 20px"></v-divider>
          <v-row justify="center" class="my-4">
            <v-col cols="12">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="(item, i) in expansions" :key="i">
                  <v-expansion-panel-header>{{
                    item.name
                  }}</v-expansion-panel-header>
                  <v-expansion-panel-content v-if="item.value === 1">
                    <v-col cols="12">
                      <v-radio-group v-model="isBuyMe" hide-details>
                        <v-radio
                          v-for="buyIt in buyIts"
                          :key="buyIt.select"
                          :label="buyIt.select"
                          :value="buyIt.value"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col cols="12" class="text-left mb-n4">ชื่อ</v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :v-model="
                          buyIt.value === 1 ? 'firstName' : 'firstNameOther'
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-left mb-n4">นามสกุล</v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :v-model="
                          buyIt.value === 1 ? 'lastName' : 'lastNameOther'
                        "
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="text-left mb-n4">เบอร์โทร</v-col>
                    <v-col cols="12">
                      <v-text-field
                        dense
                        outlined
                        hide-details
                        :v-model="buyIt.value === 1 ? 'tel' : 'telOther'"
                      ></v-text-field>
                    </v-col>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row> -->
          <v-divider style="margin-top: 20px"></v-divider>
          <v-row class="my-8" justify="center">
            <span style="color: red"
              >*กรณียังไม่ชำระเงิน คนอื่นสามารถซื้อตัดหน้าได้!</span
            >
          </v-row>
          <div
            v-if="paymentDetail"
            style="
              border: 1px solid white;
              margin-top: 30px;
              border-radius: 20px;
            "
          >
            <v-row class="white" style="color: black">
              <!-- <v-row v-if="tabs == 'qrcode'"> -->
              <v-col cols="12">
                <br />
                <p><u>ช่องทางการชำระเงิน</u></p>
              </v-col>
              <v-toolbar color="cyan" flat>
                <v-tabs color="black">
                  <v-tabs-slider color=""></v-tabs-slider>
                  <v-tab
                    v-for="item in tabitems"
                    :key="item.key"
                    @click="tab(item)"
                    style="color: black"
                  >
                    <span style="font-size: 12px">{{ item.text }}</span>
                  </v-tab>
                </v-tabs>
              </v-toolbar>
              <v-col cols="12" class="mt-2"
                >หมายเลขคำสั่งซื้อ: {{ paymentDetail.orderNo }}</v-col
              >
              <v-col cols="12" class="mt-2"
                >ระยะเวลาในการแสกน QR code ภายใน:
                <span style="color: #f44336">15 นาที</span>
                <br />
                หรือ กรุณาชำระเงินทันที</v-col
              >
              <v-col cols="12"><b>วิธีการสแกนจ่าย</b></v-col>
              <v-col cols="12"
                >1. save บันทึกรูป QR Code เข้ามือถือหรือ
                แคปเจอร์สกรีนรูปหน้าจอ</v-col
              >
              <v-col cols="12"
                >2. เข้าแอพพลิเคชั่นธนาคาร แล้วเลือกสแกนจ่าย</v-col
              >
              <v-col cols="12"
                >หมายเหตุ หากไม่สามารถสแกนจ่ายได้
                กรุณาติดต่อเจ้าหน้าที่แอดมิน</v-col
              >
              <v-col cols="12"
                ><span style="color: red"
                  >**หากไม่ได้รับ SMS สั่งซื้อสำเร็จใน 15-30 นาที
                  ออเดอร์อาจจะส่งคำสั่งซื้อไม่สำเร็จ
                  กรุณาติดตอเจ้าหน้าแอดมินทางไลน์ หรือ โทร. 02-114-1326**
                </span></v-col
              >

              <v-col cols="12">
                <v-img
                  max-width="300px"
                  class="mx-auto my-5"
                  :src="paymentDetail.image"
                  id="qrcode"
                ></v-img>
                <p>
                  ยอดชำระ: <b> {{ totalPrice }}</b> บาท
                </p>
              </v-col>
            </v-row>
          </div>
          <!-- <v-row class="mt-2" dense justify="center">
            <v-col cols="10">
              <span style="color: white; font-weight: 800; font-size: 20px">
                เลขที่บัญชี {{ bankNo }}
              </span>
              <v-icon @click="copy()">mdi-clipboard-multiple-outline </v-icon>
            </v-col>
            <v-col cols="12">
              <span style="color: white; font-weight: 800; font-size: 20px">
                SCB ธนาคารไทยพาณิชย์
              </span>
            </v-col>
            <v-col cols="12">
              <span style="color: white; font-weight: 800; font-size: 20px">
                ชื่อบัญชี บริษัท มังกร ดิจิตอล จำกัด
              </span>
            </v-col>
          </v-row> -->
          <!-- <v-row align="center" justify="center" class="mb-2">
            <v-col cols="10" md="10" sm="6" xs="12">
              <v-card outlined>
                <v-row no-gutters align="center" justify="center">
                  <v-col cols="12" class="pt-6">
                    <span>อัพโหลดรูปสลิปที่นี่</span>
                    <span style="color: red">*</span>
                  </v-col>
                  <v-col cols="11" class="mt-5 ma-10" id="step-3">
                    <v-row
                      align="center"
                      justify="center"
                      @click="changePic1()"
                    >
                      <v-img
                        v-model="picture_1"
                        src="@/assets/upload.png"
                        class="mr-3"
                        max-width="50"
                      >
                      </v-img>
                      <span>เลือกรูปภาพที่นี่</span>
                    </v-row>
                    <v-row align="center" justify="center" class="mt-4">
                      <v-col
                        cols="5"
                        md="5"
                        class="
                          pl-5
                          pr-5
                          pb-5
                          row
                          fill-height
                          sortable-list
                          align-center
                        "
                      >
                        <v-card dense light class="pa-1" v-if="showImage">
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-icon small light @click="RemoveImage()"
                              >mdi-close</v-icon
                            >
                          </v-card-actions>

                          <v-img
                            :src="showImage"
                            width="200px"
                            aspect-ratio="0.9"
                            contain
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                    <input
                      type="file"
                      ref="image"
                      id="picTure1"
                      accept="image/png"
                      @change="showPicture1($event)"
                      style="display: none"
                      hidden
                  /></v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row> -->

          <!-- <v-row align="center" justify="center" class="mb-4">
            <v-btn
              @click="afterTransaction()"
              style="
                background: linear-gradient(180deg, #fbe599 0%, #d5ab61 100%);
              "
            >
              จอง / อัพโหลดสลิป</v-btn
            >
          </v-row> -->
        </v-form>
      </v-card>
    </v-row>
  </div>
</template>
<script>
import ModaltermOfServices from "@/components/User/Modal/ModalTermofServices";
import { decode } from "@/services/decode";
import { encode } from "@/services/encode";
// let province = require("@/ADDRESS/province.json");
// let subdistrict = require("@/ADDRESS/subdistrict.json");
// let district = require("@/ADDRESS/district.json");
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    Loading,
  },
  data() {
    return {
      tabitems: [
        {
          text: "ชำระโดย QR code",
          key: "qrcode",
        },
        // {
        //   text: "ชำระโดยการ โอนผ่านธนาคาร",
        //   key: "deposit",
        // },
      ],
      loading: false,
      paymentDetail: null,
      bankNo: "4290963486",
      pic1: [],
      picture_1: "",
      showImage: "",
      imageName1: "",
      payna: false,
      lazy: false,
      carts: [],
      lotteries: [],
      userData: "",
      totalPrice: 0,
      totalAmount: 0,
      sumTotal: 0,
      cardNo: "",
      cardName: "",
      expireMonth: "",
      expireYear: "",
      ccv: "",
      address: "",
      subdistrict: "",
      district: "",
      province: "",
      postcode: "",
      delivery: "",
      firstName: "",
      lastName: "",
      tel: "",
      bankName: "",
      bankNo: "",
      firstNameOther: "",
      lastNameOther: "",
      telOther: "",
      bankNameOther: "",
      bankNoOther: "",
      transactionNo: "",
      items: [
        { text: "เก็บในตู้เซพ", value: "0" },
        { text: "จัดส่งไปรษณีย์ EMS", value: "1" },
      ],
      Rules: {
        cardNo: [(v) => !!v || "กรุณาระบุหมายเลขบัตร"],
        cardName: [(v) => !!v || "กรุณาระบุชื่อผู้ถือเลขบัตร"],
        expireMonth: [(v) => !!v || "กรุณาระบุเดือน/ปี ที่หมดอายุ"],
        expireYear: [(v) => !!v || "กรุณาระบุปีที่หมดอายุ"],
        ccv: [(v) => !!v || "กรุณาระบุ ccv"],
        empty: [(v) => !!v || "กรุณากรอกข้อมูล"],
      },
      trasactionId: "",
      //   districtItems: district,
      //   provinceItems: province,

      size: 300,
      consumerId: "UTcAW1n8UQMGgD4WQGcNuuG5qL94Zg4B",
      consumerSecret: "ZYpoyVeZhjfkdkww",
      //   consumerId: "suDxvMLTLYsQwL1R0L9UL1m8Ceoibmcr",
      //   consumerSecret: "goOfPtGLoGxYP3DG",
      Authorization: "",
      access_token: "",
      date: "",
      value: "",
      // value: "https://example.com",
      size: 300,
      feePrice: 0,
      expansions: [
        { name: "ชื่อ - นามสกุล ผู้สั่งซื้อ", value: 1 },
        { name: "บัญชีรับเงินเมื่อถูกรางวัล", value: 2 },
        {
          name: "ข้อมูลที่อยู่สำหรับผู้ที่ต้องการให้จัดส่งลอตเตอรี่",
          value: 3,
        },
      ],
      buyIts: [
        { select: "ซื้อให้ตนเอง (ฝากลอตเตอรี่ไว้ในระบบ)", value: 1 },
        { select: "ซื้อให้คนอื่น", value: 2 },
      ],
      isBuyMe: 1,
    };
  },
  async created() {
    this.getCart();

    this.delivery = "0";
    // console.log("province", this.provinceItems);
    if (Object.prototype.hasOwnProperty.call(localStorage, "DDLotUser")) {
      this.StatusLogin = true;
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      console.log("userdata", user);
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/users/` + user.user.id,
        auth
      );
      console.log("responseUser", response);
      this.id = response.data.data.id;
      this.firstName = response.data.data.firstName;
      this.lastName = response.data.data.lastName;
      this.tel = response.data.data.telNumber;
      this.email = response.data.data.email;
      // this.waranty = response.data.data.waranty
      //   ? response.data.data.waranty
      //   : 0;
      this.bankName = response.data.data.bankName
        ? response.data.data.bankName
        : "";
      this.bankNo = response.data.data.bankNo ? response.data.data.bankNo : "";
      if (
        response.data.data.firstName !== "" ||
        response.data.data.lastName !== ""
      ) {
        this.completeRegister = true;
      }
    }
  },
  components: {
    ModaltermOfServices,
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.bankNo);
      this.$swal.fire({
        icon: "success",
        title: "คัดลอกสำเร็จ",
        showConfirmButton: false,
        timer: 1000,
      });
    },
    async pay(carts) {
      if (this.$refs.form1.validate(true)) {
        const paynamcartsentData = {
          isDelivery: this.delivery,
          address: {
            address: this.address,
            subDistrict: this.subdistrict,
            district: this.district,
            province: this.province,
            postcode: this.postcode,
          },
        };

        await this.beforeTransaction(carts, paynamcartsentData);
        await this.paynamentMethods(paymcartsentData);
      }
    },
    async beforeTransaction(carts, paymcartsentData) {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      var agent = localStorage.getItem("agentCode");
      var agentData;
      var agentId = null;
      if (agent && agent !== "undefined") {
        const response = await this.axios.get(
          `${process.env.VUE_APP_API}/agents/` + agent
        );
        agentData = response.data.data;
        console.log("response", response.data.data);
        agentId = agentData.id;
      }
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      //   console.log("ข้อมูลuser", user);
      //   console.log("carts", carts);
      carts.forEach((cart) => {
        const data = {
          lotteryNo: cart.lottery.lotteryNo,
          lotteryId: cart.lotteryId,
          agentId: agentId,
        };
        this.lotteries.push(data);
      });
      console.log("ตะกร้าก่อนส่ง", this.lotteries);
      const data = {
        totalPrice: this.totalPrice,
        waranty: paymcartsentData.waranty,
        isDelivery: paymcartsentData.isDelivery,
        deliveryAddress: paymcartsentData.address,
        status: "PENDING",
        deliveryStatus: "WAITING",
        userId: user.user.id,
        lotteries: this.lotteries,
        slipImg: "",
        agentId: agentId,
      };
      console.log("dataที่ส่งไปbefore", data);
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/transaction/create`,
        data,
        auth
      );
      console.log("สำเร็จbefore", response.data);

      // this.authorizationKbank();
      if (response.data.response_status == "ERROR") {
        this.$swal.fire({
          icon: "warning",
          title: response.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        this.loading = true;
        this.trasactionId = response.data.data.id;
        const headers = {
          authorization:
            "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6ImEyOGY4YmUzM2NhY2MzNTBlZjUxZTYxNTM5NmYxMmM4ODkzNTM2ZWE4N2I1ODdmZGZhMTc1OTMwNmRhYjcyNWEzZmYxOTAxYzAzYzE5YjcyIn0.eyJhdWQiOiIyIiwianRpIjoiYTI4ZjhiZTMzY2FjYzM1MGVmNTFlNjE1Mzk2ZjEyYzg4OTM1MzZlYTg3YjU4N2ZkZmExNzU5MzA2ZGFiNzI1YTNmZjE5MDFjMDNjMTliNzIiLCJpYXQiOjE2NDAzOTE0MTEsIm5iZiI6MTY0MDM5MTQxMSwiZXhwIjoxNzk4MTU3ODExLCJzdWIiOiI5NzIzNDE5MiIsInNjb3BlcyI6W119.rkcfL1dimu68gcNVFwZJZV3Jc23dxPIra66BYmN-iMofEzxnqZZ51iJCAw8EEMhWuvbmU_vK1wHsWwFDiibf-RdhQDmOjuIS5uShWu62aKCKutmE39xBXN08aeEeoC0DTE13SkBqlVyzYYemxcy_NQfZbUz8szo5Ec0VOnHtM_QmWm-VN6zXPn8dRSAAbSITp10oKMMtg2sgU5KCrKCdGW3httlJax1AiFDTKjKtGWrW-bYftXPtzZpgBQ74qbtfx04j3HYFu0zcrBMUy6kaebM2HL6JHXYuAyqvpCDOqJbE9_1JuewcqIXQorIl8iUd5E8eojlhgR0KWGoBxcXGx5CuR0871D4Lly243SD3KByBscCrVjaIWGQHuqkjdVaiiWHSTrDR_mORy_qFts6Umr-5goG30psDCez4SG6-un89MGd_OxA9DYeFg1FL8RqiyIjrMU44dhVWjN4EfZ_KWscrId9tPHElJ2eNr7My6d8jIbkijrlRPTtd9s6iQLW--IXzw3MfAm4lVvMhgM3CkNPJi1-rfeeNYwBUliS3hKLDXPJYoyCN9amc0UjCGA01zkY10QD5aE59Yx4BL2kT_nosMoYJafM7dTVU8DXAx6O0PIuuGKlohWJ2udzs1vqcUb6pXav25gWSSdlRHepWJH_WrH17t-99se6G-YR2JYQ",
          accept: "application/json",
        };

        var userEmail = "order%40ddlottery.com";

        if (user.user.email !== "" && user.user.email) {
          userEmail = user.user.email.replace("@", "%40");
        }

        // this.totalPrice = 1;
        const totalPrice2 = this.totalPrice + paymcartsentData.waranty;
        console.log("totalPrice2", totalPrice2);
        const responseConfig = await this.axios.post(
          `https://apis.paysolutions.asia/tep/api/v1/promptpay?merchantID=97234192&productDetail=Thai_Lottery&customerEmail=${userEmail}&customerName=${user.user.firstName} ${user.user.lastName}&total=${totalPrice2}.00&referenceNo=00900${this.trasactionId}`,
          {},
          {
            headers: headers,
          }
        );
        console.log("payment Detail", responseConfig.data.data);
        this.paymentDetail = responseConfig.data.data;

        var updateParam = {
          paysolutionId: this.paymentDetail.orderNo,
        };
        console.log(updateParam);
        this.loading = false;

        const resUpdateTransaction = await this.axios.put(
          `${process.env.VUE_APP_API}/transaction/update/${this.trasactionId}`,
          updateParam,
          auth
        );
        var that = this;
        const interval = setInterval(async function() {
          // method to be executed;
          const result = await that.axios.get(
            `${process.env.VUE_APP_API}/transaction/checkPaysolutionStatusThai/${response.data.data.id}`
          );
          if (result.data.response_status == "SUCCESS") {
            clearInterval(interval);
            that.$swal
              .fire({
                icon: "success",
                title: "การสั่งซื้อสำเร็จ",
                text:
                  " หากลูกค้าไม่ได้รับ sms ยืนยันออเดอร์ใน 1 ชม. ให้ติดต่อสอบถามเจ้าหน้าที่แอดมินทันที",
                showConfirmButton: true,
              })
              .then(async (result) => {
                if (result.isConfirmed) {
                  that.loading = false;
                  that.$router.push({ path: "/safe" }).catch(() => {});
                }
              });
          }
        }, 5000);
        this.loading = false;

        await this.$swal.fire({
          icon: "success",
          text: "กรุณาชำระเงินด้านล่าง",
          showConfirmButton: false,
          timer: 1000,
        });

        await document.getElementById("qrcode").focus();

        // }).then(async (result) => {
        await window.scrollTo(0, document.body.scrollHeight);
        this.loading = false;
      }
    },
    // paymentMethods(paymcartsentData) {
    //   paymcartsentData.status = "SUCCESS";
    //   this.afterTransaction(paymcartsentData);
    // },
    async afterTransaction() {
      console.log("this.pi1", this.pic1);
      // console.log("length.pi1", this.pic1.length);

      this.$swal({
        text: "ยืนยันการจอง/ส่งหลักฐานการโอนเงิน ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "ยกเลิก",
        confirmButtonText: "ยืนยัน",
      }).then(async (result) => {
        if (result.isConfirmed) {
          if (this.totalPrice == 0) {
            this.$swal.fire({
              icon: "error",
              title: "ไม่มีสินค้าในตะกร้า",
              showConfirmButton: false,
              timer: 1500,
            });
            this.$router.push("/");
            console.log("เข้าไม่มีสินค้า");
            // } else if (!this.pic1 || this.pic1.length == 0) {
            //   this.$swal.fire({
            //     icon: "error",
            //     title: "กรุณาอัพรูปหลักฐานการโอนเงิน",
            //     showConfirmButton: false,
            //     timer: 1500,
            //   });
            //   console.log("เข้าไม่มีรูป");
          } else if (this.totalPrice !== 0) {
            // } else if (this.totalPrice !== 0 && this.pic1.length != 0) {
            console.log("ผ่าน");
            var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
            var paymcartsentData = JSON.parse(
              decode(localStorage.getItem("paymcartsentData"))
            );
            paymcartsentData.status = "PENDING"; ///////////////////////
            const auth = {
              headers: { Authorization: `Bearer ${user.token}` },
            };

            const data = {
              status: paymcartsentData.status,
              slipImg: this.pic1,
            };
            console.log("dataที่ส่งไปafter", data);
            console.log("this.trasactionId", this.trasactionId);
            const response = await this.axios.put(
              `${process.env.VUE_APP_API}/transaction/update/` +
                this.trasactionId,
              data,
              auth
            );
            console.log("สำเร็จafter", response.data);
            if (response.data.response_status == "ERROR") {
              this.$swal.fire({
                icon: "warning",
                title: response.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.$swal.fire({
                icon: "success",
                // title: response.data.message,
                title: "อัพโหลดสลิปสำเร็จ",
                showConfirmButton: false,
                timer: 1500,
              });
              this.$router.push("transaction");
              localStorage.setItem("tSuccess", encode(this.trasactionId));
            }
          }
        }
      });
      //updateput trx trxid = status success
    },
    home() {
      this.$router.push("/lotterythai");
    },
    async getCart() {
      var user = JSON.parse(decode(localStorage.getItem("DDLotUser")));
      var paymcartsentData = JSON.parse(
        decode(localStorage.getItem("paymcartsentData"))
      );
      const auth = {
        headers: { Authorization: `Bearer ${user.token}` },
      };
      // console.log("user", user.user.id);
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/cart/findAllByUser/` + user.user.id,
        auth
      );
      console.log("response", response);
      this.carts = response.data.data;

      const responseConfig = await this.axios.get(
        `${process.env.VUE_APP_API}/configs/findByType/OPERATION_PRICE`,
        auth
      );
      console.log("responseConfig", responseConfig.data.data);
      this.feePrice = responseConfig.data.data.value1 * 1;

      this.carts.forEach((cart) => {
        cart.lottery.urlImage = cart.lottery.urlImage.replace(
          "20210425",
          "20210424"
        );
        // this.totalPrice = this.totalPrice + cart.lottery.price * cart.amount;
        this.totalPrice =
          this.totalPrice +
          (cart.lottery.price + responseConfig.data.data.value1 * 1) *
            cart.amount;
        this.totalAmount = this.totalAmount + cart.amount;
      });
      if (this.carts !== [] || !this.carts) {
        this.beforeTransaction(this.carts, paymcartsentData);
      }
      //   console.log(this.totalPrice);
      //   console.log(this.totalAmount);
    },
    async authorizationKbank() {
      var base64 = btoa(this.consumerId + ":" + this.consumerSecret);
      //   console.log("base64", base64);
      this.Authorization = "Basic " + base64;
      console.log("Authorization", this.Authorization);

      // var axios = require("axios");
      // var qs = require("qs");
      // var data = qs.stringify({
      //   grant_type: "client_credentials",
      // });
      // var config = {
      //   method: "post",
      //   url: "https://openapi-sandbox.kasikornbank.com/oauth/token",
      //   headers: {
      //     Authorization: this.Authorization,
      //     "Content-Type": "application/x-www-form-urlencoded",
      //     "env-id": "OAUTH2",
      //     "x-test-mode": "true",
      //   },
      //   data: data,
      // };

      // const response = await axios(config);

      // .then(function (response) {
      //   //   console.log(JSON.stringify(response.data));
      //   console.log("response", response.data);
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });

      // console.log("response", response);
      // this.access_token = response.data.access_token;

      this.getQR();
    },
    async getQR() {
      // console.log("this.accesstokennnnnnn", this.access_token);
      this.value = "https://example.com";
      // var axios = require("axios");
      // var data = JSON.stringify({
      //   partnerId: "PTR1051673",
      //   partnerSecret: "d4bded59200547bc85903574a293831b",
      //   merchantId: "KB102057149704",
      //   QRType: 3,
      //   amount: 200,
      //   currencyCode: "THB",
      // });

      // var config = {
      //   method: "post",
      //   url: "https://openapi-sandbox.kasikornbank.com/v1/qrpayment/request",
      //   headers: {
      //     Authorization: "Bearer " + this.access_token,
      //     "Content-Type": "application/json",
      //     "x-test-mode": "true",
      //     "env-id": "QR002",
      //   },
      //   data: data,
      // };

      // const response = await axios(config);
      // .then(function (response) {
      //   console.log(JSON.stringify(response.data));
      // })
      // .catch(function (error) {
      //   console.log(error);
      // });
      // console.log("ถ้านผ่านตรงนี้ เรียก after trans", response);
    },
    paystatus() {
      this.payna = true;
      if ((this.payna = true)) {
        alert("จ่ายเงินสำเร็จ!!");
        this.afterTransaction();
      } else {
        alert("จ่ายเงินไม่สำเร็จ");
      }
    },
    showPicture1(e) {
      // console.log('index',index);
      const files = e.target.files;
      if (files[0] !== undefined) {
        this.imageName1 = files[0].name;
        const element = files[0];
        const reader = new FileReader();
        reader.readAsDataURL(element);
        reader.onload = () => {
          this.pic1 = reader.result;
          this.showImage = URL.createObjectURL(element);
          // console.log(this.pic1);
        };
      }
    },
    changePic1() {
      document.getElementById("picTure1").click();
    },
    onPickFile() {
      document.getElementById("file_input").click();
    },
    RemoveImage(index, item) {
      // console.log(index, item);
      // if (item.id !== "-1") {
      //   this.Remove_img.push({
      //     id: item.id,
      //   });
      // }
      // this.product_image.splice(index, 1);
      this.pic1 = [];
      this.picture_1 = "";
      this.showImage = "";
      this.imageName1 = "";
    },
  },
};
</script>
<style scoped>
.sec-cart {
  border-radius: 8px;
  padding: 8px;
  background: #2560a1;
  /* margin: 0 -12px 20px; */
  color: #fff;
}
.lotto-group {
  position: relative;
  display: block;
  padding-top: 47%;
  z-index: 0;
  background-color: #e8dfc4;
}

.lotto-scroll {
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
  -webkit-overflow-scrolling: touch;
}
.lotto-scroll .lotto-group {
  display: flex;
  width: auto;
  margin: 0 20px 0 0;
  padding: 0 0 10px;
  background: 0 0;
}
.lotto-group .info1 {
  position: absolute;
  z-index: 0;
  top: 0;
  bottom: 0;
  left: 0;
  min-width: 60px;
  max-height: 120px;
  background: rgba(255, 94, 239, 0.85);
  backdrop-filter: blur(4px);
  text-align: center;
  color: #000;
  border-radius: 0 0 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  line-height: 1.1;
  font-size: 10px;
  padding: 10px 0;
}
.lotto-scroll .lotto-group .pic {
  display: flex;
  position: relative;
  top: unset;
  left: unset;
  bottom: unset;
  overflow: unset;
  width: auto;
  align-items: flex-start;
}
.sizeimg {
  width: 40vw;
}
</style>
